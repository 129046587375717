
//disabale cache via jquery
$.ajaxSetup({cache: false});

// function datumfield(){
// 	$('.datumfield').datepicker({
// 		format: "yyyy-mm-dd",
// 		todayBtn: "linked",
// 		language: "nl",
// 		calendarWeeks: true,
// 		autoclose: true,
// 		todayHighlight: true,
// 	});
// }

// function datumfieldAutosend(year,month,day,formid){
// 	$('.datumfieldAutosend').datepicker({
// 		format: "yyyy-mm-dd",
// 		todayBtn: "linked",
// 		language: "nl",
// 		calendarWeeks: true,
// 		autoclose: true,
// 		todayHighlight: true,
// 		defaultViewDate: { year: year, month: month, day: day }
// 	}).on('changeDate', function(e){
// 		$(formid).submit();
//
// 	});
// }

function verplaatsPiForm(loader,button,postData,urlData,method){

		return $.ajax({
			beforeSend: function(){
				$(loader).show();
				$(button).hide();
			},
			cache:false,
			type: method,
			url: urlData,
			data:  postData,
			success: function(msg){
				// $(button).show();
				// $(loader).hide();
				// location.reload(); 
				// window.location = "http://" + window.location.host + window.location.pathname + '?datum='+postData["datum"]+"#"+msg.replace(/\"/g,"");
			}

        }); // Ajax Call
}

function SendAjaxForm(loader,button,postData,urlData,method){

		return $.ajax({
			beforeSend: function(){
				$(loader).show();
				$(button).hide();
			},
			cache:false,
			type: method,
			url: urlData,
			data:  postData,
			success: function(msg){
				$(loader).hide();
				$(button).show();
				return msg;
			}

        }); // Ajax Call
}

function getChecked(id){

    var ArrChecked = [];

    $(id).each(function () {
        var ischecked = $(this).is(":checked");
        if (ischecked) {
            ArrChecked.push( $(this).val());
        }
    });

    return ArrChecked;

}

function wait(ms) {
      var deferred = $.Deferred();
      setTimeout(deferred.resolve, ms);

     // We just need to return the promise not the whole deferred.
     return deferred.promise();
  }

function simplecolorAutosend(rowid){
	var idpikleur = "IdpiKleur" + rowid;
	var formname = "UpdateIdpiKleur" + rowid
  	$('.'+ idpikleur).simpleColor({
    	boxWidth:20,
    	onSelect: function(hex, element) {

        $('#kleur').val('#'+hex);
        $('#'+formname).submit();

    	}
  });



}

function getUrlParam(){
	var oParametre = {};

	if (window.location.search.length > 1) {
		for (var aItKey, nKeyId = 0, aCouples = window.location.search.substr(1).split("&"); nKeyId < aCouples.length; nKeyId++) {
			aItKey = aCouples[nKeyId].split("=");
			oParametre[unescape(aItKey[0])] = aItKey.length > 1 ? unescape(aItKey[1]) : "";
		}
	}

	return oParametre;
};
