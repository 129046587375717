/**
 * Dutch translation for bootstrap-datepicker
 * Reinier Goltstein <mrgoltstein@gmail.com>
 */
(function () {
    Datepicker.locales.nl = {
        days: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
        daysShort: ["zo", "ma", "di", "wo", "do", "vr", "za"],
        daysMin: ["zo", "ma", "di", "wo", "do", "vr", "za"],
        months: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
        monthsShort: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
        today: "Vandaag",
        monthsTitle: "Maanden",
        clear: "Wissen",
        weekStart: 1,
        format: "dd-mm-yyyy"
    };
}());